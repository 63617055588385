import Api from "api/Api";
import type { ApiResponse } from "api/types";
import type { AxiosPromise } from "axios";
import { GIT_BASE_URL } from "git/requests/constants";

export type ToggleCDResponseData = boolean;

export type ToggleCDResponse = ApiResponse<ToggleCDResponseData>;

export default async function toggleCDRequest(
  baseApplicationId: string,
): AxiosPromise<ToggleCDResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/auto-deployment/toggle/app/${baseApplicationId}`,
  );
}
