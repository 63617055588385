import Api from "api/Api";
import { GIT_BASE_URL } from "git/requests/constants";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "api/types";

export interface UpdateDefaultBranchRequestParams {
  branchName: string;
}

export type UpdateDefaultBranchResponse = ApiResponse<void>;

export default async function updateDefaultBranchRequest(
  baseApplicationId: string,
  params: UpdateDefaultBranchRequestParams,
): AxiosPromise<UpdateDefaultBranchResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/branch/app/${baseApplicationId}/default`,
    {},
    undefined,
    { params },
  );
}
