import { api } from "api/core";
import PluginsApi from "api/PluginApi";
import { toast } from "@appsmith/ads";
import type { EmbeddingGenerators, Integration } from "carbon-connect";
import type {
  FetchAccessTokenResponse,
  FetchDocumentsResponse,
  FetchIntegrationsResponse,
  RagDocument,
  ResyncDocumentResponse,
} from "./types";

export const fetchIntegrations = async (
  datasourceId: string,
  workspaceId: string,
): Promise<{
  integrations: Integration[];
  embeddingModel: keyof typeof EmbeddingGenerators;
}> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchIntegrationsResponse>(apiUrl, {
    datasourceId,
    workspaceId,
    requestType: "GET_RAG_SERVICE_INTEGRATION_CONFIGURATION",
    displayType: "DROP_DOWN",
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }

  return {
    integrations: response.data.trigger.config.integrations,
    embeddingModel: response.data.trigger.config.embeddingModel,
  };
};

export const fetchAccessToken = async (
  datasourceId: string,
  workspaceId: string,
): Promise<{ access_token: string }> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchAccessTokenResponse>(apiUrl, {
    datasourceId,
    workspaceId,
    requestType: "GENERATE_RAG_SERVICE_ACCESS_TOKEN",
    displayType: "DROP_DOWN",
  });

  if (!response.responseMeta.success) {
    if (response.responseMeta.error) {
      toast.show(response.responseMeta.error.message, { kind: "error" });
    }

    return Promise.reject(response.responseMeta.error);
  }

  return { access_token: response.data.trigger.accessToken };
};

export const fetchDocuments = async (
  datasourceId: string,
): Promise<{ count: number | null; documents: RagDocument[] }> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchDocumentsResponse>(apiUrl, {
    datasourceId,
    requestType: "LIST_DOCUMENTS",
    displayType: "DROP_DOWN",
  });

  if (!response.responseMeta.success) {
    if (response.responseMeta.error) {
      toast.show(response.responseMeta.error.message, { kind: "error" });
    }

    return Promise.reject(response.responseMeta.error);
  }

  return {
    count: response.data.trigger.count || null,
    documents: response.data.trigger.results || [],
  };
};

export const resyncDocumentRequest = async (
  datasourceId: string,
  documentId: string,
) => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<ResyncDocumentResponse>(apiUrl, {
    datasourceId,
    requestType: "RESYNC_DOCUMENT",
    displayType: "DROP_DOWN",
    parameters: {
      documentId,
    },
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }

  return response.data.trigger.document;
};

export const deleteDocumentRequest = async (
  datasourceId: string,
  documentId: string,
) => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<void>(apiUrl, {
    datasourceId,
    requestType: "DELETE_DOCUMENTS",
    displayType: "DROP_DOWN",
    parameters: {
      documentIds: [documentId],
    },
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }
};
