import type {
  DocumentStatus,
  DocumentType,
  RagDocument,
} from "ee/components/formControls/RagDocuments/types";
import moment from "moment/moment";

export const getBadgeKind = (value: DocumentStatus) => {
  switch (value) {
    case "READY":
      return "success";
    case "SYNCING" || "UNKNOWN":
      return "warning";
    case "SYNC_ERROR":
      return "error";
  }
};

export const getDocumentIcon = (value: DocumentType) => {
  switch (value) {
    case "TSV":
      return "tsv-file";
    case "CSV":
      return "csv-file";
    case "RTF":
      return "rtf-file";
    case "DOC" || "DOCX":
      return "doc-file";
    case "PPT" || "PPTX":
      return "ppt-file";
    case "XLSX" || "XLSM":
      return "xls-file";
    case "JSON":
      return "json-file";
    case "PDF":
      return "pdf-file";
    case "TEXT":
      return "txt-file";
    case "MD":
      return "md-file";
    case "NOTION":
      return "notion";
    case "WEB_SCRAPE":
      return "global-line";
    case "ZENDESK":
      return "zendesk";
    case "GOOGLE_DRIVE":
      return "google-drive";
    case "SALESFORCE":
      return "salesforce";
    default:
      return "file-line";
  }
};

export const mapDocumentsToTableData = (documents: RagDocument[]) => {
  return documents.map((result) => {
    return {
      key: result.ragId,
      col1: result.syncStatus,
      col2: result.type,
      col3: {
        name: result.name,
        url: result.isWebSource ? result.source : result.documentUrl,
      },
      col4: result.type,
      col5: result.size,
      col6: result.updatedAt,
      col7: result.ragId,
      col8: result.ragId,
    };
  });
};

export const isoStringToLocalDate = (isoString: string) => {
  // Use moment to parse and format the date in the local time zone
  return moment(isoString).local().format("DD.MM.YY HH:mm:ss");
};
